import { useRevalidator } from '@remix-run/react'
import * as React from 'react'
import {
	getClientHintCheckScript,
	subscribeToSchemeChangeAndRevalidate,
} from './client-hints-utils'

/**
 * @returns inline script element that checks for client hints and sets cookies
 * if they are not set then reloads the page if any cookie was set to an
 * inaccurate value.
 */
export function ClientHintCheck({ nonce }: { nonce: string }) {
	const { revalidate } = useRevalidator()
	React.useEffect(
		() => subscribeToSchemeChangeAndRevalidate(revalidate),
		[revalidate],
	)
	return (
		<script
			nonce={nonce}
			dangerouslySetInnerHTML={{
				__html: getClientHintCheckScript(),
			}}
		/>
	)
}
