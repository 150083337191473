import { Link } from '@remix-run/react'

export function FooterComponent() {
	return (
		<footer className="mt-8 flex-col bg-footer py-4 text-sm text-forefooter">
			<div className="container flex justify-between px-4">
				<div className="flex gap-4 lowercase">
					<span>
						<Link to="/about">About</Link>
					</span>
					<span>
						<Link to="/privacy">Privacy</Link>
					</span>
				</div>
				<div></div>
			</div>
		</footer>
	)
}
