import { getFormProps, useForm } from '@conform-to/react'
import { parseWithZod } from '@conform-to/zod'
import { invariantResponse } from '@epic-web/invariant'
import {
	type HeadersFunction,
	type LinksFunction,
	unstable_defineAction as defineAction,
	unstable_defineLoader as defineLoader,
	MetaArgs,
	MetaDescriptor,
} from '@remix-run/cloudflare'
import {
	Form,
	json,
	Link,
	Links,
	Meta,
	NavLink,
	Outlet,
	Scripts,
	ScrollRestoration,
	useFetcher,
	useFetchers,
	useLoaderData,
	useMatch,
	useRouteLoaderData,
	useSubmit,
} from '@remix-run/react'

import { MoonIcon, SunIcon, ArrowLeftIcon } from 'lucide-react'
import React, { useRef, useState } from 'react'
import { z } from 'zod'

// Assets & styles
import { FooterComponent } from './components/main/footer'
import {
	AdminBarIndex,
	NavigationSheet,
	NavigationmMenu,
} from './components/navigation-menu'
import { EpicProgress } from './components/progress-bar'
import { AdminHeaderLinks, dashboardNavigation } from './components/sidelinks'
import { Spacer } from './components/spacer'
import { useToast } from './components/toaster'
import { Button } from './components/ui/button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuPortal,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from './components/ui/dropdown-menu'
import { EpicToaster } from './components/ui/sonner'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from './components/ui/tooltip'
import { getHints, useHints } from './utils/client-hints-utils'
import {
	Default_User_Avtar,
	cn,
	combineHeaders,
	getDomainUrl,
} from './utils/misc'
import { useRequestInfo } from './utils/request-info'
import { SiteTitlet, TheSiteName, useSetting } from './utils/seo'
import { makeTimings, time } from './utils/timing.server'
import { getToast } from './utils/toast.server'
import { useOptionalAdmin, useOptionalUser, useUser } from './utils/user'
import tailwindStyleSheet from '~/assets/css/tailwind.css?url'

import lficon from '~/assets/favicon.ico'
import { GeneralErrorBoundary } from '~/components/error-boundary'
// import Header from '~/components/main/header'
import { logout, getUserId } from '~/utils/auth.server'
import { ClientHintCheck } from '~/utils/client-hints'
import { useNonce } from '~/utils/nonce-provider'
import { getTheme, setTheme, type Theme } from '~/utils/theme.server'
import { csrf } from './utils/csrf.server'
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react'
import { honeypot } from './utils/honeypot.server'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { loader as adminLoader } from '~/routes/_admincustom+/admin'
import { AdminSideItemsheet } from './components/main/sidebar'

// 'edge': This is a special runtime introduced in Remix.js for the �Single Fetch� feature. It combines both server-side and client-side rendering in a way that optimizes data fetching during client-side transitions.
export const config = {
	runtime: 'edge',
}

// --------------------------------------- LinksFunction --------------------------------------- //

export const links: LinksFunction = () => [
	{ rel: 'preload', href: lficon, as: 'image' },
	{ rel: 'stylesheet', href: tailwindStyleSheet },

	{ rel: 'icon', type: 'icon', href: lficon },
	{ rel: 'preconnect', href: 'https://fonts.googleapis.com' },
	// Set crossOrigin to "anonymous" explicitly
	{
		rel: 'preconnect',
		href: 'https://fonts.gstatic.com',
		crossOrigin: 'anonymous',
	},
	{
		rel: 'stylesheet',
		href: 'https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,700,800',
	},
]

// ].filter(Boolean)

// --------------------------------------- HEADERSFUNCTION --------------------------------------- //

export const headers: HeadersFunction = ({ loaderHeaders }) => {
	const headers = {
		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
	}
	return headers
}

const ThemeFormSchema = z.object({
	theme: z.enum(['system', 'light', 'dark']),
})

// --------------------------------------- ACTION --------------------------------------- //

export const action = defineAction(async ({ request }) => {
	const formData = await request.formData()
	const submission = parseWithZod(formData, {
		schema: ThemeFormSchema,
	})

	invariantResponse(submission.status === 'success', 'Invalid theme received')

	const { theme } = submission.value

	const responseInit = {
		headers: { 'set-cookie': setTheme(theme) },
	}

	return json({ result: submission.reply() }, responseInit)
})

// --------------------------------------- META --------------------------------------- //

export function meta({ data }: MetaArgs<typeof loader>): MetaDescriptor[] {
	if (data === undefined) {
		return []
	}

	return [
		{ title: SiteTitlet() },
		{ name: 'description', content: 'defaultDescription' },
	]
}

// export function meta({ data }: MetaArgs<typeof loader>): MetaDescriptor[] {
//   if (data === undefined) {
//     return [];
//   }

//   return [
// 		{ title: TheSiteName('site_title') },
// 		{ name: 'description', content: 'defaultDescription' },
// 	]
// }

export const loader = defineLoader(async ({ request, context }) => {
	const timings = makeTimings('root loader')

	const userId = await time(() => getUserId(request, context), {
		timings,
		type: 'getUserId',
		desc: 'getUserId in root',
	})

	const user = userId
		? await time(
				() =>
					context.db.query.users.findFirst({
						where: (users, { eq }) => eq(users.id, userId),
						with: {
							image: true,
						},
					}),
				{ timings, type: 'find user', desc: 'find user in root' },
			)
		: null
	if (userId && !user) {
		console.info('something weird happened')
		// something weird happened... The user is authenticated but we can't find
		// them in the database. Maybe they were deleted? Let's log them out.
		await logout({ request, redirectTo: '/', context })
	}

	const { toast, headers: toastHeaders } = await getToast(request)

	const sitedata = await context.db.query.settings.findMany({})

	const site_logo = sitedata.find((setting) => setting.name === 'site_logo')

	const categorieslist = await context.db.query.categories.findMany({})

	const honeyProps = honeypot(
		context.cloudflare.env.HONEYPOT_SECRET,
	).getInputProps()
	const [csrfToken, csrfCookieHeader] = await csrf(
		context.cloudflare.env.SESSION_SECRET,
	).commitToken()

	return json(
		{
			categorieslist,
			sitedata,
			site_logo,
			user,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: new URL(request.url).pathname,
				userPrefs: {
					theme: getTheme(request),
				},
			},
			toast,
			csrfToken,
			honeyProps,
		},

		{
			headers: combineHeaders(
				{ 'Server-Timing': timings.toString() },
				toastHeaders,
				csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null,
			),
		},
	)
})

function Layout({
	children,
	nonce,
	theme = 'light',
	allowIndexing = true,
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	allowIndexing?: boolean
}) {
	const data = useLoaderData<typeof loader>()
	const user = useOptionalUser()

	const isAdminRoute = useMatch('/admin/*')
	const showSpacer = !isAdminRoute

	const isuserAdmin = useOptionalAdmin()

	const site_title = SiteTitlet()
	useToast(data?.toast)

	const HeaderNavigation = data.categorieslist?.map((category) => ({
		label: category.name,
		to: `/${category.slug}`,
		icon: undefined, // replace with the icon you want to use
	}))

	return (
		<html lang="en" className={`${theme} h-full`}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				{allowIndexing ? null : (
					<meta name="robots" content="noindex, nofollow" />
				)}

				<Meta />
				<Links />
			</head>
			<body className="flex h-full flex-col bg-background text-foreground">
				{showSpacer ? (
					<>
						{isuserAdmin ? (
							<div className="bg-orange-600">
								<div className="container flex justify-between text-white">
									<span></span>

									<AdminBarIndex dataMap={AdminHeaderLinks} />
								</div>
							</div>
						) : (
							''
						)}
						<header className="border-b border-gray-200 bg-gradient-custom">
							<div
								className={
									showSpacer ? 'container mx-auto flex justify-between' : ''
								}
							>
								<div className="flex items-center">
									{data.categorieslist ? (
										<NavigationSheet dataMap={HeaderNavigation} />
									) : (
										''
									)}

									<span className="mx-2 md:mx-0">
										<Link to="/">
											<span className="flex justify-center whitespace-nowrap text-h2 text-foreheader">
												{site_title}
											</span>
										</Link>
									</span>
									<div className="container hidden items-center self-center md:flex">
										{data.categorieslist ? (
											<NavigationmMenu dataMap={HeaderNavigation} />
										) : (
											''
										)}
									</div>
								</div>

								<div className="flex items-center gap-2">
									{user ? (
										<UserDropdown />
									) : (
										<Button asChild variant="link" size="default">
											<NavLink
												to="/login"
												className={({ isActive }) =>
													cn('', isActive && 'underline')
												}
											>
												Log In
											</NavLink>
										</Button>
									)}

									<ThemeSwitch
										userPreference={data?.requestInfo?.userPrefs.theme}
									/>
								</div>
							</div>
						</header>
					</>
				) : (
					<AdminHeader />
				)}

				{children}

				<EpicToaster
					dir="ltr"
					expand={false}
					closeButton
					position="bottom-right"
					theme={theme}
				/>
				<EpicProgress />

				{showSpacer ? (
					<>
						<FooterComponent />
					</>
				) : (
					''
				)}

				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

function AdminHeader() {
	const data = useLoaderData<typeof loader>()

	const site_title = SiteTitlet()

	if (!useOptionalAdmin()) {
		return null
	}

	const counts =
		useRouteLoaderData<typeof adminLoader>('routes/_admincustom+/admin') ?? {}

	return (
		<div className="flex bg-sidebar">
			{/* Header section */}
			<header className="flex h-12 grow items-center gap-2 border-b px-4 lg:h-[60px] lg:px-6">
				<AdminSideItemsheet navigation={dashboardNavigation} counts={counts} />

				<div className="text-h2">
					<Link to="">{site_title}</Link>
				</div>

				{/* Search input container */}
				<div className="relative flex grow"></div>

				<div className="flex justify-between">
					<span></span>
					<span className="flex items-center gap-2">
						<ThemeSwitch userPreference={data?.requestInfo?.userPrefs.theme} />

						<Link to="">
							<TooltipProvider>
								<Tooltip>
									<TooltipTrigger asChild>
										<Button size="icon" variant="shadow">
											<ArrowLeftIcon />
										</Button>
									</TooltipTrigger>
									<TooltipContent>
										<p>Back to main site</p>
									</TooltipContent>
								</Tooltip>
							</TooltipProvider>
						</Link>
					</span>
				</div>

				<UserDropdown />
			</header>
		</div>
	)
}

function App() {
	const theme = useTheme()
	const nonce = useNonce()

	const allowIndexing = true
	const isAdminRoute = useMatch('/admin/*')
	const showSpacer = !isAdminRoute

	return (
		<Layout nonce={nonce} theme={theme} allowIndexing={allowIndexing}>
			<div className={showSpacer ? 'container mx-auto flex-1' : ''}>
				{showSpacer && <Spacer size="4xs" />}
				<Outlet />
				{showSpacer && <Spacer size="4xs" />}
			</div>
		</Layout>
	)
}

export default function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>
	)
}

function UserDropdown() {
	const user = useUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="shadow">
					<Link
						to={`/users/${user.username}`}
						// this is for progressive enhancement
						onClick={(e) => e.preventDefault()}
						className="flex items-center gap-2"
					>
						<img
							className="h-8 w-8 rounded-full object-cover"
							alt={user.name ?? user.username}
							src={user.image?.cloudinarySecureUrl || Default_User_Avtar}
						/>
						<span className="text-body-sm">{user.name ?? user.username}</span>
					</Link>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent align="end">
					<DropdownMenuLabel>My Account</DropdownMenuLabel>
					{/* <DropdownMenuItem>
						<Link prefetch="intent" to={`/users/${user.username}`}>
							My Profile
						</Link>
					</DropdownMenuItem> */}
					<Link prefetch="intent" to="/settings/profile">
						<DropdownMenuItem>Edit Profile</DropdownMenuItem>
					</Link>
					<DropdownMenuSeparator />
					<DropdownMenuItem
						// this prevents the menu from closing before the form submission is completed
						onSelect={(event) => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<button type="submit" className="w-full">
								Logout
							</button>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}

/**
 * @returns the user's theme preference, or the client hint theme if the user
 * has not set a preference.
 */
function useTheme() {
	const hints = useHints()
	const requestInfo = useRequestInfo()

	const optimisticMode = useOptimisticThemeMode()
	if (optimisticMode) {
		return optimisticMode === 'system' ? hints.theme : optimisticMode
	}
	return requestInfo.userPrefs.theme ?? hints.theme
}

/**
 * If the user's changing their theme mode preference, this will return the
 * value it's being changed to.
 */
function useOptimisticThemeMode() {
	const fetchers = useFetchers()
	const themeFetcher = fetchers.find((f) => f.formAction === '/')

	if (themeFetcher && themeFetcher.formData) {
		const submission = parseWithZod(themeFetcher.formData, {
			schema: ThemeFormSchema,
		})

		if (submission.status === 'success') {
			return submission.value.theme
		}
	}
}

function ThemeSwitch({ userPreference }: { userPreference?: Theme | null }) {
	const fetcher = useFetcher<typeof action>()

	const [form] = useForm({
		id: 'theme-switch',
		lastResult: fetcher.data?.result,
	})

	const optimisticMode = useOptimisticThemeMode()
	const mode = optimisticMode ?? userPreference ?? 'light'
	const nextMode = mode === 'light' ? 'dark' : 'light'
	// const mode = optimisticMode ?? userPreference ?? 'system'
	// const nextMode = mode === 'light' ? 'dark' : 'light'

	const modeLabel = {
		light: <SunIcon size={20} />,
		dark: <MoonIcon size={20} />,
		system: 'test',
	}

	return (
		<fetcher.Form method="POST" {...getFormProps(form)}>
			<input type="hidden" name="theme" value={nextMode} />
			<div className="flex cursor-pointer gap-2 rounded-lg text-foreground transition-all">
				<Button
					variant="ghost"
					size="icon"
					type="submit"
					className="flex h-9 w-9 cursor-pointer items-center justify-center"
				>
					{modeLabel[mode]}
				</Button>
			</div>
		</fetcher.Form>
	)
}

export function ErrorBoundary() {
	const nonce = useNonce()
	// the nonce doesn't rely on the loader so we can access that

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.

	return (
		<Layout nonce={nonce}>
			<GeneralErrorBoundary />
		</Layout>
	)
}
