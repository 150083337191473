import { Form, Link, NavLink, useRouteLoaderData } from '@remix-run/react'
import { Menu } from 'lucide-react'
import { Badge } from './ui/badge'
import { Button } from './ui/button'
import { NavigationMenu, NavigationMenuList } from './ui/navigation-menu'
import { Sheet, SheetContent, SheetTrigger } from './ui/sheet'
import { type loader as adminLoader } from '~/routes/_admincustom+/admin'
import { cn } from '~/utils/misc'
import { SiteTitlet } from '~/utils/seo'

const iconsize = '20'

interface NavItem {
	to: string
	label: string
	icon?: React.ComponentType<any>
}

interface NavigationProps {
	dataMap: NavItem[]
	data?: {
		posts_count?: number
		users_count?: number
		[key: string]: any
	}
}

export function NavigationmMenu({ dataMap }: NavigationProps) {
	const navLinkDefaultClassName =
		'flex items-center gap-2 px-3 py-4 text-foreground transition hover:bg-muted hover:text-foreground'
	return (
		<NavigationMenu>
			<NavigationMenuList className="text-sm capitalize">
				{dataMap.map((navItem) => (
					<NavLink
						key={navItem.to}
						to={navItem.to}
						className={({ isActive }) =>
							cn(navLinkDefaultClassName, isActive && 'bg-navhover')
						}
						preventScrollReset={true}
					>
						{navItem.icon && <navItem.icon size={iconsize} />}
						{navItem.label}
						{/* {navItem.label === 'Notes' ? (
							<Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
								14
							</Badge>
						) : null} */}
					</NavLink>
				))}
			</NavigationMenuList>
		</NavigationMenu>
	)
}

export function AdminBarIndex({ dataMap }: NavigationProps) {
	const navLinkDefaultClassName =
		'flex items-center gap-2 px-3 py-4 text-header transition hover:bg-muted hover:text-foreground'
	return (
		<NavigationMenu>
			<NavigationMenuList className="text-sm capitalize">
				{dataMap.map((navItem) => (
					<NavLink
						key={navItem.to}
						to={navItem.to}
						className={({ isActive }) =>
							cn(navLinkDefaultClassName, isActive && 'bg-navhover')
						}
						preventScrollReset={true}
					>
						{navItem.icon && <navItem.icon size={iconsize} />}
						{navItem.label}
						{/* {navItem.label === 'Notes' ? (
							<Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
								14
							</Badge>
						) : null} */}
					</NavLink>
				))}
			</NavigationMenuList>
		</NavigationMenu>
	)
}

export function NavigationSheet({ dataMap }: NavigationProps) {
	const data = useRouteLoaderData<typeof adminLoader>(
		'routes/_admincustom+/admin',
	)
	return (
		<Sheet>
			<SheetTrigger asChild>
				<Button variant="link" size="icon" className="shrink-0 md:hidden">
					<Menu className="h-5 w-5" />
					<span className="sr-only">Toggle navigation menu</span>
				</Button>
			</SheetTrigger>
			<SheetContent side="left" className="flex flex-col">
				<nav className="grid gap-2 text-lg font-medium">
					<Link
						to="/"
						className="flex items-center gap-2 text-lg font-semibold"
					>
						{SiteTitlet()}
					</Link>
					{dataMap.map((navItem) => (
						<Link
							key={navItem.to}
							to={navItem.to}
							className="mx-[-0.65rem] flex items-center gap-4 px-3 py-2 text-muted-foreground hover:text-foreground"
							preventScrollReset={true}
						>
							{navItem.icon && <navItem.icon size={iconsize} />}
							{navItem.label}
							{navItem.label === 'Posts' &&
							data.posts_total_count !== undefined ? (
								<Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
									{data.posts_total_count.toString()}
								</Badge>
							) : null}
							{navItem.label === 'Users' &&
							data.users_total_count !== undefined ? (
								<Badge className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-full">
									{data.users_total_count.toString()}
								</Badge>
							) : null}
						</Link>
					))}
				</nav>
				<div className="mt-auto">
					<Form action="/logout" method="post">
						<Button type="submit" variant="ghost">
							&larr; Log out
						</Button>
					</Form>
				</div>
			</SheetContent>
		</Sheet>
	)
}
