import { invariant } from '@epic-web/invariant'
import { useRouteLoaderData } from '@remix-run/react'
import axios from 'axios'
import { type loader as rootLoader } from '~/root'

/**
 * @returns the request info from the root loader
 */
export function useRequestInfo() {
	const data = useRouteLoaderData<typeof rootLoader>('root')
	invariant(data?.requestInfo, 'No requestInfo found in root loader')

	return data.requestInfo
}

export function createApi(requestinfoo: any) {
	const api = axios.create({
		baseURL: requestinfoo.origin.toString(),
		headers: {
			'Content-Type': 'application/json',
		},
	})

	return api
}
